<template>
  <!-- 网页标签标题 -->
  <div v-wechat-title="$route.meta.title"></div>
  <!-- 表格 -->
  <Table
    :columns="columns"
    :getData="selectArticlelike"
    :subHeight=215
    ref="table"
  >
    <template v-slot="{ column, record }">
      <template v-if="column.dataIndex == 'is_like'">
        <template v-if="record.is_like == 0">
          <HeartOutlined style="color:#888888;font-size:18px" />
        </template>
        <template v-else>
          <HeartFilled style="color:#F56C6C;font-size:18px" />
        </template>
      </template>

    </template>
  </Table>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import Table from '../components/Table.vue'
import { SELECTARTICLELIKE } from '../api/api'
import { onMounted } from '@vue/runtime-core'
import { HeartOutlined, HeartFilled } from '@ant-design/icons-vue'
export default {
  components: {
    Table,
    HeartOutlined,
    HeartFilled
  },
  setup() {
    const table = ref(null)
    onMounted(() => {
      table.value.getTableData()
    })
    // 表格字段
    const columns = ref([
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        align: 'center'
      },
      {
        title: '文章',
        dataIndex: 'article_title',
        key: 'article_title',
        width: 200,
        align: 'center',
        ellipsis: true
      },
      {
        title: '用户昵称',
        dataIndex: 'user_nickname',
        key: 'user_nickname',
        width: 120,
        align: 'center',
        ellipsis: true
      },
      {
        title: '点赞状态',
        dataIndex: 'is_like',
        key: 'is_like',
        width: 80,
        align: 'center'
      },
      {
        title: '创建时间',
        dataIndex: 'create_time_toDate',
        isEdit: false,
        key: 'create_time_toDate',
        width: 200,
        align: 'center'
      },
      {
        title: '更新时间',
        dataIndex: 'modified_time_toDate',
        isEdit: false,
        key: 'modified_time_toDate',
        width: 200,
        align: 'center'
      }
    ])
    // 查询表单
    const selectLikeFrom = reactive({})
    // 查询数据方法
    const selectArticlelike = (pageData) => {
      return new Promise((resolve, reject) => {
        SELECTARTICLELIKE(Object.assign(pageData, selectLikeFrom))
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
    return {
      table,
      columns,
      selectArticlelike
    }
  }
}
</script>

<style>
</style>
